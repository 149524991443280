export class ArrayUtils {

    public static toDistinct(input: any[] | undefined | null): any[] | undefined | null {
        if (!input?.length) return input;

        return input.filter((v, i, a) => a.indexOf(v) === i);
    }

    public static merge(input: any[][]): any[] | undefined | null {
        if (!input?.length) return input;

        return input.filter(x => !!x?.length).reduce((prev, curr) => prev.push(...curr) && prev, []);
    }
}
