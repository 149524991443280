import {LocationModel} from '@app/core/resource-dto/location.model';

export class CoordinateUtil {

  private static readonly NULLISH = [null, undefined];
  private static readonly COORDINATE_TO_RADIAN = Math.PI / 180;
  public static readonly EARTH_RADIUS_METERS = 6371e3;

  public static calculateDistanceInMeters(a: LocationModel, b: LocationModel): number | undefined {
    if (!CoordinateUtil.hasCoordinates(a) || !CoordinateUtil.hasCoordinates(b)) return undefined;

    const aLatitudeRadians = a.latitude * CoordinateUtil.COORDINATE_TO_RADIAN;
    const bLatitudeRadians = b.latitude * CoordinateUtil.COORDINATE_TO_RADIAN;
    const latitudeDelta = ((b.latitude - a.latitude) * CoordinateUtil.COORDINATE_TO_RADIAN) / 2;
    const longitudeDelta = ((b.longitude - a.longitude) * CoordinateUtil.COORDINATE_TO_RADIAN) / 2;

    const x = Math.pow(Math.sin(latitudeDelta), 2) +
      Math.cos(aLatitudeRadians) * Math.cos(bLatitudeRadians) *
      Math.pow(Math.sin(longitudeDelta), 2);
    const y = 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x));

    return y * CoordinateUtil.EARTH_RADIUS_METERS;
  }

  public static hasCoordinates(location: LocationModel): boolean {
    if (!location) return false;

    return !CoordinateUtil.NULLISH.includes(location.latitude) &&
           !CoordinateUtil.NULLISH.includes(location.longitude);
  }
}
