export class ObjectUtils {

    public static hasValue(value: any): boolean {
        if ([undefined, null].includes(value)) return false;
        if ((typeof value) === 'string') return !value.trim().length;
        if (value.length !== undefined && !!value.length) return false;
        if ((typeof value) === 'object') return !!Object.keys(value).length;

        return true;
    }

    public static firstNonEmpty(...values: any[]): any {
        for (const value of values) {
            if (ObjectUtils.hasValue(value)) return value;
        }

        return undefined;
    }

    public static toObject(array: [string, any][]): any {
        if (array.filter(a => a.length !== 2).length) throw new Error('Only accepts array of tuple arrays!');

        return array.reduce((out, item) => {
            out[item[0]] = item[1];

            return out;
        }, {});
    }

    public static copyValues(copyTo: any, from: any): void {
        Object.keys(from).forEach(key => copyTo[key] = from[key]);
    }

    public static filterEmptyValues(input: any): void {
        Object.keys(input).forEach(key => {
            const val = input[key];
            if (val !== null && val !== undefined && val?.length !== 0 && val?.size !== 0) return;

            delete input[key];
        });
    }

    public static filterFalseValues(input: any): void {
        Object.keys(input).forEach(key => {
            const val = input[key];
            if (val !== false) return;

            delete input[key];
        });
    }

    public static areEqual(a: any, b: any): boolean {
        return !this.keysWithDifferentValues(a, b).length;
    }

    public static keysWithDifferentValues<T>(a: T, b: T): string[] {
        if (!a && !b) return [];
        if (!a) return Object.keys(b);
        if (!b) return Object.keys(a);

        const allKeys = [...Object.keys(a), ...Object.keys(b)];

        return allKeys.filter(key => !ObjectUtils.areEqualValues(a[key], b[key]));
    }

    public static areEqualValues(a: any, b: any): boolean {
        if (a === undefined) return b === undefined;
        if (a === null) return b == null;
        if (b === undefined || b === null) return false;

        const aType = typeof a;
        const bType = typeof b;
        if (aType !== bType) return false;
        if (aType !== 'object') return a === b;
        if (a.constructor !== b.constructor) return false;

        if (a instanceof Date) {
            return a.getTime() === b.getTime();
        }

        return a.toString() === b.toString();
    }
}
