import {HttpHeaders} from '@angular/common/http';
import * as moment from 'moment/moment';

export class AuthInterceptorHelpers {

    public static getExpirationDate(headers: HttpHeaders): Date | undefined {
        if (!headers) return undefined;
        if (!headers.has('x-session-expires')) return undefined;

        const expirationString = headers.get('x-session-expires');
        if (!expirationString?.length) return undefined;

        const expirationMoment = moment(expirationString);
        if (!expirationMoment.isValid()) return undefined;

        return expirationMoment.toDate();
    }
}
