export class ActivityUserResponse {

    public id: number;
    public forename: string;
    public surname: string;

    public static from(json?: any): ActivityUserResponse | undefined {
        if (!json) return undefined;

        const output = new ActivityUserResponse();
        output.id = json?.id;
        output.forename = json?.forename;
        output.surname = json?.surname;

        return output;
    }
}
