import {Component, OnDestroy} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserNotificationPermissionService} from '@app/core/services/user-notification-permission.service';
import {UserNotificationPermissionsDto} from '@app/core/resource-dto/user-notification-permissions';
import UserNotificationSettingsDto = UserNotificationPermissionsDto.UserNotificationSettingsDto;
import {PermissionsService} from '@app/shared/services/permissions.service';

@Component({
    templateUrl: './user-notification-permissions-modal.component.html',
})
export class UserNotificationPermissionsModalComponent implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    public readonly substituteManagePermission$: Promise<boolean> = this.permissionService
        .hasPermissionAsync('substitute-role-permission-substitute.manage');
    public currentSettings: UserNotificationSettingsDto = null;

    constructor(
        private service: UserNotificationPermissionService,
        private modalRef: BsModalRef,
        private permissionService: PermissionsService
    ) {
        this.listenToUserNotificationPermissions();
    }

    public saveAndClose(): void {
        this.service.put(this.currentSettings);
        this.modalRef.hide();
    }

    public ngOnDestroy(): void {
        if (!!this.modalRef) {
            this.modalRef.hide();
            this.modalRef = undefined;
        }

        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public close(): void {
        this.modalRef.hide();
    }

    private listenToUserNotificationPermissions(): void {
        this.service.userNotificationPermissions$.pipe(takeUntil(this.ngDestroy))
            .subscribe(settings => this.currentSettings = settings);
    }
}
