<ng-container [formGroup]="orderForm">
    <div class="form-row custom-form-row">
        <div class="col-12">
            <label>{{ 'Nimi' | translate }}</label>
            <div class="w-100">
                <input formControlName="requesterName" class="form-control"
                       [attr.placeholder]="'Taotleja nimi' | translate" [class.is-invalid]="formErrors.requesterName">
                <small *ngIf="formErrors?.requesterName?.required" style="display: block;"
                       class="invalid-feedback">{{ formErrors?.requesterName?.required }}</small>
            </div>
        </div>
    </div>

    <div class="form-row custom-form-row">
        <div class="col-12">
            <label>{{ 'E-post' | translate }}</label>
            <div class="w-100">
                {{orderForm.get('requesterEmail')?.errors?.pattern}}
                <input type="email" class="form-control" [class.is-invalid]="formErrors.requesterEmail"
                       [attr.placeholder]="'E-post' | translate" formControlName="requesterEmail">
                <small *ngIf="(formErrors?.requesterEmail?.email ||
                formErrors?.requesterEmail?.required ||
                orderForm.get('requesterEmail')?.errors?.pattern)"
                       style="display: block;" class="invalid-feedback ml-0">{{ formErrors?.requesterEmail?.email }}
                </small>
                <small
                    *ngIf="formService.orderForm.touched && (formErrors?.requesterEmail?.email || formErrors?.atLeastOne)"
                    style="display: block;" class="invalid-feedback ml-0">{{ formErrors?.atLeastOne }}
                </small>
            </div>
        </div>
    </div>

    <div class="form-row custom-form-row">
        <div class="col-12">
            <label>{{ 'Telefon' | translate }}</label>
            <div class="w-100">
                <input class="form-control" [class.is-invalid]="formErrors.requesterPhone"
                       [attr.placeholder]="'Telefon' | translate" formControlName="requesterPhone">
                <small *ngIf="(formErrors?.requesterPhone?.required)"
                       class="d-block invalid-feedback ml-0">{{ formErrors?.requesterPhone?.required }}
                </small>
                <small *ngIf="(formErrors?.requesterPhone?.pattern)"
                       class="d-block invalid-feedback ml-0">
                    {{ formErrors?.requesterPhone?.pattern }}
                </small>
                <small
                    *ngIf="formService.orderForm.touched && (formErrors?.requesterPhone?.email || formErrors?.atLeastOne)"
                    class="d-block invalid-feedback ml-0">
                    {{ formErrors?.atLeastOne }}
                </small>
            </div>
        </div>
    </div>
</ng-container>
