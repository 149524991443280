import {Injectable, TemplateRef} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject, timer} from 'rxjs';
import {Moment} from 'moment';
import * as moment from 'moment';
import {BadgeState} from './navbar-badge/badge-state';
import {MonthParams} from './month-params.interface';

@Injectable({providedIn: 'root'})
export class NavbarService {

    private readonly badgesSource = new ReplaySubject<BadgeState>();
    private readonly actionsTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
    private readonly footerTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
    private readonly breadcrumbsSource = new BehaviorSubject([]);

    public readonly actionsTemplate$: Observable<TemplateRef<any>> = this.actionsTemplateSource.asObservable();
    public readonly footerTemplate$: Observable<TemplateRef<any>> = this.footerTemplateSource.asObservable();
    public readonly breadcrumbs$: Observable<any[]> = this.breadcrumbsSource.asObservable();
    public readonly badges$: Observable<BadgeState> = this.badgesSource.asObservable();
    private readonly badgeState = new BadgeState();
    public readonly contractPermissionList = [
        'maintenance-contract.access.all-contracts',
        'maintenance-contract.access.company-contracts',
        'maintenance-contract.access.property-contracts',
        'maintenance-contract.access.regional-property-contracts',
        'access.records-contractor-project-manager',
        'access.records-contractor-dispatcher',
        'access.records-contractor-works-manager',
        'access.records-rkik',
        'access.records-prison-tallinn',
        'access.records-prison-tartu',
        'access.records-prison-viru',
        'access.records-all-except-prison',
        'th.access.records-north',
        'th.access.records-south',
        'th.access.records-east',
        'th.access.records-west',
        'hk.access.records-north',
        'hk.access.records-south',
        'hk.access.records-east',
        'hk.access.records-west',
        'tt.access.records-north',
        'tt.access.records-south',
        'tt.access.records-east',
        'tt.access.records-west',
        'construction-contract.access'
    ];

    public readonly monthTick$ = timer(0, 60 * 1000).pipe(
        map(_ => this.getCurrentMonth()),
        distinctUntilChanged((x, y) => x.isSame(y)),
        startWith(this.getCurrentMonth())
    );
    public readonly currentMonthParams$: Observable<any> = this.monthTick$
        .pipe(map(month => this.toMonthParams(month)));
    public readonly nextMonthParams$: Observable<any> = this.monthTick$
        .pipe(map(month => this.toMonthParams(month.clone().add(1, 'month'))));

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.listenToRouterEvents();
    }

    public setActionsTemplate(template: TemplateRef<any>) {
        this.actionsTemplateSource.next(template);
    }

    public setFooterTemplate(template: TemplateRef<any>) {
        this.footerTemplateSource.next(template);
    }

    private listenToRouterEvents(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.generateBreadcrumbs.bind(this));
    }

    private generateBreadcrumbs(): void {
        const breadcrumbs = [];
        let actionsComponent = null;

        let route = this.route.snapshot;

        while (route?.outlet === 'primary') {
            let next = null;
            if (!!route.data.breadcrumb && !breadcrumbs.includes(route.data.breadcrumb)) {
                route.data.breadcrumb.path = this.getFullPath(route);
                breadcrumbs.push(route.data.breadcrumb);
            }

            if (route.data.navbarActionsComponent) {
                actionsComponent = route.data.navbarActionsComponent;
            }

            route.children.forEach(child => {
                if (child.outlet === 'primary') {
                    next = child;
                }
            });

            route = next;
        }

        this.breadcrumbsSource.next(breadcrumbs);
    }

    public update(acts: number, inspections: number, submittedOverdue: number, messages: number): void {
        this.badgeState.inspections = inspections;
        this.badgeState.inspectionClass = submittedOverdue > 0 ? 'badge-danger' : 'badge-success';
        this.badgeState.acts = acts;
        this.badgeState.messages = messages;
        this.badgeState.messagesClass = messages > 0 ? 'badge-danger' : '';
        this.badgesSource.next(this.badgeState);
    }

    private getFullPath(route: ActivatedRouteSnapshot): string[] {
        const output = [];
        let current = route;
        while (!!current) {
            const segments = current.url.map(e => e.path);
            segments.reverse().forEach(segment => output.unshift(segment));
            current = current.parent;
        }

        return output;
    }

    private getCurrentMonth(): Moment {
        return moment().startOf('month');
    }

    private toMonthParams(aMoment: Moment): MonthParams {
        return {
            year: aMoment.year(),
            month: aMoment.month() + 1
        };
    }
}
