import {Coordinates} from '@app/core/models/';

export class MouseUtils {

    public static setCursor(cursor?: string): void {
        if (!!cursor?.length) {
            document.body.style.cursor = cursor;
        } else {
            document.body.style.removeProperty('cursor');
        }
    }

    public static getClientPosition(event: MouseEvent): Coordinates {
        return new Coordinates(event?.clientX, event?.clientY);
    }
}
