<div class="work-details-full-width mt-4 pb-3 pl-0">
    <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">

            <div>
                <h5 class="mb-0">
                    Seadmete hierarhia vaate värskendamine
                </h5>
            </div>

            <button class="btn btn-primary"
                    type="button"
                    [disabled]="!(enabled$ | async)"
                    (click)="submit()">
                Värskenda
            </button>

        </div>
    </div>
</div>
