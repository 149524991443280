<div class="modal-header">
    <h4 id="dialog-sizes-name1"
        class="modal-title pull-left">
        E-posti teavitused
    </h4>
    <button class="close pull-right"
            aria-label="Sulge"
            type="button"
            (click)="close()">
        <span aria-hidden="true" class="submenu-hover"></span>
        <span aria-hidden="true" class="icon icon_close"></span>
    </button>
</div>

<div class="modal-body">
    <div class="bg-light p-3">
        <h5>{{ currentSettings.name }}</h5>
        <div *ngFor="let role of currentSettings.roles">
            <div *ngIf="role.permissions.length" class="row pt-3">
                <div class="col-2">
                    <label>{{ role.name }}</label>
                </div>
                <div class="col-6">
                    <div *ngFor="let permission of role.permissions"
                         class="custom-control custom-checkbox">
                        <input id="checkbox_{{role.id}}_{{permission.id}}"
                               class="custom-control-input"
                               type="checkbox"
                               [disabled]="(currentSettings.isSubstitute && (substituteManagePermission$ | async) === false) || permission.alwaysActive"
                               [(ngModel)]="permission.isActive">
                        <label for="checkbox_{{role.id}}_{{permission.id}}"
                               class="custom-control-label no-wrap">
                            {{ permission.name }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer footer-padding">
    <button class="btn btn-outline-primary"
            type="button"
            (click)="close()">
        Katkesta
    </button>
    <button class="btn btn-primary"
            type="button"
            (click)="saveAndClose()">
        Salvesta
    </button>
</div>
