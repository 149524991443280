import {FormArray, FormControl, FormGroup} from '@angular/forms';

export const setAsTouched = (group: FormGroup | FormArray): void => {
  group.markAsTouched({onlySelf: true});
  group.markAsDirty({onlySelf: true});
  for (const i in group.controls) {
    if (group.controls[i] instanceof FormControl) {
      group.controls[i].markAsTouched({onlySelf: true});
      group.controls[i].markAsDirty({onlySelf: true});
    } else {
      setAsTouched(group.controls[i]);
    }
  }
};
