import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NgxPermissionsGuard} from 'ngx-permissions';
import {UserNotFoundComponent} from '@app/core/components/user-not-found/user-not-found.component';
import {GDPRGuard} from '@app/shared/guards/gdpr.guard';
import {CreateQuestComponent} from '@app/order/create-quest/create-quest.component';
import {UnauthorizedComponent} from '@app/unauthorized/unauthorized.component';
import {CreateOfferComponent} from '@app/order/create-offer/create-offer.component';
import {Breadcrumb} from '@app/core/components/navbar/breadcrumbs/breadcrumb';

const routes: Routes = [
    {
        path: 'user-not-found',
        component: UserNotFoundComponent
    }, {
        path: '',
        pathMatch: 'full',
        redirectTo: 'orders',
        canActivate: [GDPRGuard]
    }, {
        path: 'orders',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
        data: {
            breadcrumb: new Breadcrumb('Töötaotlused'),
            permissions: {
                only: 'user'
            }
        },
        canActivate: [GDPRGuard, NgxPermissionsGuard]
    }, {
        path: 'warranty',
        loadChildren: () => import('./warranty/warranty.module').then(m => m.WarrantyModule),
        data: {
            permissions: {
                only: ['order.access.warranty']
            }
        },
        canActivate: [GDPRGuard, NgxPermissionsGuard]
    }, {
        path: 'reports',
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
        data: {
            breadcrumb: new Breadcrumb('Raportid'),
            permissions: {
                only: 'user'
            }
        },
        canActivate: [GDPRGuard, NgxPermissionsGuard]
    }, {
        path: 'report-a-problem',
        component: CreateQuestComponent,
        data: {
            lang: 'en'
        },
        children: [{
            path: 'ministry',
            component: CreateQuestComponent,
            data: {
                ministry: true
            }
        }]
    }, {
        path: 'teata-probleemist',
        component: CreateQuestComponent,
        data: {
            lang: 'et'
        },
        children: [{
            path: 'ministeerium',
            component: CreateQuestComponent,
            data: {
                ministry: true
            }
        }]
    }, {
        path: 'lisa-pakkumine/:token',
        component: CreateOfferComponent,
        data: {
            hideFooter: true
        }
    }, {
        path: 'inspections',
        loadChildren: () => import('./inspection/inspection.module').then(m => m.InspectionModule),
        canActivate: [NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Ülevaatused'),
            permissions: {
                only: [
                    'inspection.access.all',
                    'inspection.access.property-inspections',
                    'inspection.access.regional-property-inspections',
                    'inspection.access.client-own-building-inspections',
                    'inspection.access.company-inspections',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west',
                    'tt.access.records-north',
                    'tt.access.records-south',
                    'tt.access.records-east',
                    'tt.access.records-west',
                    'inspection.access.client-inspections',
                    'inspection.access.same-governance-area-inspections'
                ]
            }
        }
    },
    {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Teavitused'),
            permissions: {
                only: ['messages.access.all']
            }
        }
    },
    {
        path: 'messages-user',
        loadChildren: () => import('./messages/messages-user/messages-user.module').then(m => m.MessagesUserModule),
        canActivate: [NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Kasutaja teavitused')
        }
    },
        {
        path: 'construction-contracts',
        loadChildren: () => import('./construction-contracts/construction-contracts.module').then(m => m.ConstructionContractModule),
        canActivate: [NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Ehituslepingud'),
            permissions: {
                only: ['construction-contract.access']
            }
        }
    },
    {
        path: 'contracts',
        loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Korrashoiuteenuste lepingud'),
            permissions: {
                only: [
                    'maintenance-contract.access.all-contracts',
                    'maintenance-contract.access.company-contracts',
                    'maintenance-contract.access.property-contracts',
                    'maintenance-contract.access.regional-property-contracts',
                    'access.records-contractor-project-manager',
                    'access.records-contractor-dispatcher',
                    'access.records-contractor-works-manager',
                    'access.records-rkik',
                    'access.records-prison-tallinn',
                    'access.records-prison-tartu',
                    'access.records-prison-viru',
                    'access.records-all-except-prison',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west',
                    'tt.access.records-north',
                    'tt.access.records-south',
                    'tt.access.records-east',
                    'tt.access.records-west'
                ]
            }
        }
    }, {
        path: 'acts',
        loadChildren: () => import('./acts/acts.module').then(m => m.ActsModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['act.access']
            }
        }
    }, {
        path: 'appliance',
        loadChildren: () => import('./appliance/appliance.module').then(m => m.ApplianceModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            permissions: {
                only: ['appliance-rule.access']
            }
        }
    }, {
        path: 'buildings',
        loadChildren: () => import('./buildings/buildings.module').then(m => m.BuildingsModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Hooned'),
            permissions: {
                only: [
                    'building-user.manage',
                ]
            }
        }
    }, {
        path: 'roles',
        loadChildren: () => import('./admin/roles/roles.module').then(m => m.RolesModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Rolli õigused'),
            permissions: {
                only: ['role-permission.manage']
            }
        }
    }, {
        path: 'permissions',
        loadChildren: () => import('./admin/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Õiguste seosed'),
            permissions: {
                only: ['permission-permission.manage']
            }
        }
    }, {
        path: 'public-api',
        loadChildren: () => import('./admin/public-api/public-api.module').then(m => m.PublicApiModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Avalik Api'),
            permissions: {
                only: ['admin.access']
            }
        }
    }, {
        path: 'volitus-puudub',
        component: UnauthorizedComponent,
        data: {
            content: 'Ligipääs piiratud',
            hideFooter: true
        }
    }, {
        path: 'aegunud',
        component: UnauthorizedComponent,
        data: {
            content: 'Pakkumuste saatmine on juba lõppenud!',
            hideFooter: true
        }
    },
    {
        path: 'repairs',
        loadChildren: () => import('./repairs/repairs.module').then(m => m.RepairsModule),
        canActivate: [GDPRGuard, NgxPermissionsGuard],
        data: {
            breadcrumb: new Breadcrumb('Remondikoond'),
            permissions: {
                only: [
                    'repair.access.governanceArea',
                    'repair.access.company'
                ]
            }
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
