import {Component} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CacheResource} from '@app/core/resource/cache.resource';

@Component({
    selector: 'app-cache-clear',
    templateUrl: './cache-clear.component.html'
})
export class CacheClearComponent {

    public constructor(
        private cachingRes: CacheResource,
        private toastr: ToastrService
    ) {}

    public submit(): void {
        this.cachingRes.clear().then(
            _ => this.toastr.success('Vahemälu edukalt tühjendatud!'),
            error => this.toastr.error(JSON.stringify(error), 'Viga!')
        );
    }
}
