import {Injectable, OnDestroy} from '@angular/core';
import {HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, combineLatest, from, Observable, ReplaySubject, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {OrderCostOptionsDto} from '@app/core/resource-dto/order-cost-option';
import {PropertyRatesDto} from '@app/core/resource-dto/property-rates';
import {OrderEventDto} from '@app/core/resource-dto/order-event';
import {OrderCostsDto} from '@app/core/resource-dto/order-cost';
import {OrderFileDto} from '@app/core/resource-dto/order-file';
import {SharedDto} from '@app/core/resource-dto/shared-dto';
import {OrderDto, OrderSourceType} from '@app/core/resource-dto/order';
import {AuthService} from '@app/core/services/auth.service';
import {CommentType} from '@app/core/constants/comment.type';
import {OrderRes} from '@app/core/resource/order.resource';
import {InspectedServiceRes} from '@app/core/resource/inspected-service.resource';
import {OrderEventRes} from '@app/core/resource/order-event.resource';
import {OrderFileRes} from '@app/core/resource/order-file.resource';
import {NotificationRes} from '@app/core/resource/notification.resource';
import {InspectionDto} from '@app/core/resource-dto/inspection';
import {ObjectUtils} from '@app/shared/utils';
import {PermissionsService} from '@app/shared/services/permissions.service';
import Order = OrderDto.Order;
import GetOrderInput = OrderDto.GetOrderInput;
import OrderTenant = OrderDto.OrderTenant;
import OrderTenantsInput = OrderDto.TenantsInput;
import OrderViewerRequest = OrderDto.OrderViewerRequest;
import OrderEventInput = OrderDto.EventInput;
import OrderStatus = OrderDto.Status;
import OrderInput = OrderDto.OrderInput;
import OrderQuotationDueDateRequest = OrderDto.OrderQuotationDueDateRequest;
import OrderQuotationAcceptRequest = OrderDto.OrderQuotationAcceptRequest;
import OrderQuotationOfferCommentRequest = OrderDto.OrderQuotationOfferCommentRequest;
import OrderAssignee = OrderDto.Assignee;
import OrderAssigneeInput = OrderDto.AssigneeInput;
import OrderAssignees = OrderDto.AssigneesQueryOutput;
import OrderBuildingInput = OrderDto.OrderBuildingInput;
import OrderBuilding = OrderDto.Building;
import OrderVerifyWorkRequest = OrderDto.OrderVerifyWorkRequest;
import OrderCommentInput = OrderDto.CommentInput;
import OrderEvent = OrderEventDto.Event;
import OrderFile = OrderFileDto.File;
import Cost = OrderCostsDto.Cost;
import CostInput = OrderCostsDto.CostInput;
import CostsInput = OrderCostsDto.CostsInput;
import CostsOutput = OrderCostsDto.CostsOutput;
import CostOptionsOutput = OrderCostOptionsDto.CostOptionsOutput;
import IdInput = SharedDto.IdInput;
import PropertyRates = PropertyRatesDto.QueryOutput;
import CancelledInspectionOrder = InspectionDto.CancelledInspectionOrder;

@Injectable({providedIn: 'root'})
export class ManageService implements OnDestroy {

    private readonly ngDestroy = new Subject<void>();
    private readonly contextTypeSubject = new BehaviorSubject<string>('ORDER');
    private readonly loadingOrderSource = new BehaviorSubject<boolean>(true);
    private readonly orderSource = new BehaviorSubject<Order | undefined>(undefined);
    private readonly eventsSource = new ReplaySubject<OrderEvent[]>(1);
    private readonly filesSource = new BehaviorSubject<OrderFile[]>([]);
    private readonly costsSource = new ReplaySubject<CostsOutput>(1);
    private readonly propertyRatesSource = new ReplaySubject<PropertyRates>(1);
    private readonly costOptionsSource = new ReplaySubject<CostOptionsOutput>(1);
    private readonly assigneesSource = new ReplaySubject<OrderAssignees>(1);
    private readonly extensionRequestDatetimeSource = new ReplaySubject<string>(1);
    private readonly extensionRequestCommentSource = new ReplaySubject<string>(1);
    private readonly orderApprovedSource = new ReplaySubject<boolean>(1);
    private readonly stateChangeSubject = new Subject<void>();
    private readonly commentsUpdatedSource = new Subject<any>();
    private readonly costsUpdatedSource = new Subject<any>();
    private readonly refreshSubject = new Subject<void>();

    public readonly loadingOrder$ = this.loadingOrderSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly order$ = this.orderSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), filter(o => o !== undefined), distinctUntilChanged());
    public readonly events$ = this.eventsSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly files$ = this.filesSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly costs$ = this.costsSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly propertyRates$ = this.propertyRatesSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly costOptions$ = this.costOptionsSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly assignees$ = this.assigneesSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly extensionRequestDatetime$ = this.extensionRequestDatetimeSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly extensionRequestComment$ = this.extensionRequestCommentSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly isOrderApproved$ = this.orderApprovedSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly stateChange$ = this.stateChangeSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly commentsUpdated$ = this.commentsUpdatedSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly costsUpdated$ = this.costsUpdatedSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly orderId$ = this.order$
        .pipe(takeUntil(this.ngDestroy), map(x => x?.id), filter(x => x !== undefined), distinctUntilChanged());
    public readonly refresh$ = this.refreshSubject.asObservable().pipe(takeUntil(this.ngDestroy));
    public readonly refreshOrder$ = combineLatest([this.orderId$, this.refresh$.pipe(startWith(undefined))])
        .pipe(debounceTime(10), map(([x, __]) => x));

    constructor(
        private orderRes: OrderRes,
        private inspectedServiceRes: InspectedServiceRes,
        private eventRes: OrderEventRes,
        private orderFileRes: OrderFileRes,
        private notificationRes: NotificationRes,
        private authService: AuthService,
        private permissionsService: PermissionsService,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.listenForOrderUpdate();
    }

    public set assignees(assignees: OrderAssignees) {
        this.assigneesSource.next(assignees);
    }

    public get contextType(): string {
        return this.contextTypeSubject.value;
    }

    public set contextType(contextType: string) {
        this.contextTypeSubject.next(contextType);
    }

    public set costs(costs: CostsOutput) {
        this.costsSource.next(costs);
    }

    public set costOptions(costOptions: CostOptionsOutput) {
        this.costOptionsSource.next(costOptions);
    }

    public set costsUpdated(input: any) {
        this.costsUpdatedSource.next(input);
    }

    public set events(events: OrderEvent[]) {
        this.eventsSource.next(events);

        const extensionEvents = events.filter(e => e.orderEventType === 'OREV_ASK_EXTENSION');

        if (!!extensionEvents?.length) {
            const latestEvent = extensionEvents.reduce((a, b) => {
                return new Date(a.eventTimestamp) > new Date(b.eventTimestamp) ? a : b;
            });

            this.extensionRequestDatetimeSource.next(latestEvent.eventTimestamp);
            this.extensionRequestCommentSource.next(latestEvent.comment);
        } else {
            this.extensionRequestDatetimeSource.next(null);
            this.extensionRequestCommentSource.next(null);
        }
    }

    public get files(): OrderFile[] {
        return this.filesSource.value || [];
    }

    public set files(files: OrderFile[]) {
        this.filesSource.next(files);
    }

    public get order(): Order | undefined {
        return this.orderSource.value;
    }

    public set order(order: Order | undefined) {
        this.orderSource.next(order);
    }

    public set orderApproved(state: boolean) {
        this.orderApprovedSource.next(state);
    }

    public set propertyRates(propertyRates: PropertyRates) {
        this.propertyRatesSource.next(propertyRates);
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public triggerChange(): void {
        this.stateChangeSubject.next();
    }

    public refresh(): void {
        this.refreshSubject.next();
    }

    public updateOrder(): Observable<void> {
        return new Observable<void>(subscriber => {
            const orderId = this.order?.id;
            if (!orderId) return subscriber.error('Order id missing!');

            const input = GetOrderInput.from(orderId, this.contextType === 'ACT' ? CommentType.ACT : CommentType.ORDER);
            this.orderRes.get(input)
                .then(result => {
                    this.order = result;

                    return subscriber.next();
                })
                .catch(error => {
                    this.router.navigate(['/']);

                    return subscriber.error(error);
                });
        });
    }

    public loadOrder(orderId: number): void {
        this.loadingOrderSource.next(true);
        const input = GetOrderInput.from(orderId, this.contextType === 'ACT' ? CommentType.ACT : CommentType.ORDER);
        this.orderRes.get(input).then(
            res => {
                this.order = res;
                this.loadingOrderSource.next(false);
            },
            err => {
                this.toastr.error('Töötaotluse laadimise viga!');
                console.error(err);

                this.router.navigate(['/']);
            });
    }

    public loadAssignees(): Promise<void> {
        return new Promise((resolve, reject) => {
            const orderId = this.order.id;
            if (!ObjectUtils.hasValue(orderId)) {
                this.assignees = undefined;

                return resolve();
            }

            this.orderRes.queryAssignees({orderId})
                .then(assignees => {
                    this.assignees = assignees;
                    resolve();
                })
                .catch(err => {
                    this.assignees = undefined;
                    reject(err);
                });
        });
    }

    public loadCosts(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const orderId = this.order.id;
            if (!ObjectUtils.hasValue(orderId) || !this.permissionsService.hasPermission('order.cost.view')) {
                this.costs = undefined;

                return resolve();
            }

            this.orderRes.getCosts({orderId})
                .then(costs => {
                    this.costs = costs;
                    resolve();
                })
                .catch(err => {
                    this.costs = undefined;
                    if (err?.status === 403) {
                        console.error(err);
                        resolve();
                    } else {
                        reject(err);
                    }
                });
        });
    }

    public loadCostOptions(): Promise<void> {
        return new Promise((resolve, reject) => {
            const orderId = this.order.id;
            if (!ObjectUtils.hasValue(orderId) || !this.permissionsService.hasPermission('order.cost.manage')) {
                this.costOptions = undefined;

                return resolve();
            }

            this.orderRes.getCostOptions({orderId})
                .then(costOptions => {
                    this.costOptions = costOptions;
                    resolve();
                })
                .catch(err => {
                    this.costOptions = undefined;
                    if (err?.status === 403) {
                        console.error(err);
                        resolve();
                    } else {
                        reject(err);
                    }
                });
        });
    }

    public loadEvents(): Promise<void> {
        return new Promise((resolve, reject) => {
            const orderId = this.order.id;
            if (!ObjectUtils.hasValue(orderId) || !this.permissionsService.hasPermission('order-event.access')) {
                this.events = [];

                return resolve();
            }

            this.eventRes.query({orderId})
                .then(events => {
                    this.events = events.events;
                    resolve();
                })
                .catch(err => {
                    this.events = [];
                    if (err?.status === 403) {
                        console.error(err);
                        resolve();
                    } else {
                        reject(err);
                    }
                });
        });
    }

    public loadFiles(): Promise<void> {
        return new Promise((resolve, reject) => {
            const orderId = this.order.id;
            if (!ObjectUtils.hasValue(orderId)) {
                this.files = [];

                return resolve();
            }

            this.orderRes.queryFiles({orderId})
                .then(files => {
                    this.files = files.files;
                    resolve();
                })
                .catch(err => {
                    this.files = [];
                    reject(err);
                });
        });
    }

    public clearNotifications(): void {
        if (!this.order || !this.order.notifications) {
            return;
        }

        this.order.notifications.forEach(notification => {
            this.notificationRes.delete({notificationId: notification.id});
        });
    }

    public createComment(orderId: number, text: string, userIds?: number[]): Promise<any> {
        const input = new OrderCommentInput();
        input.orderId = orderId;
        input.text = text;
        input.commentType = this.contextType === 'ACT' ? 'ORCO_ACT' : 'ORCO_ORDER';
        if (userIds) {
            input.userIds = userIds;
        }

        return this.orderRes.saveComment(input, null, {orderId: input.orderId})
            .then(() => this.commentsUpdatedSource.next());
    }

    public saveInspectedService(input: OrderInput): Promise<any> {
        if (input.inspection && input.inspection.id) {
            return this.inspectedServiceRes.update(input, null, {inspectionId: input.inspection.id});
        } else {
            return this.saveOrder(input);
        }
    }

    public saveOrder(input: OrderInput): Promise<Order> {
        if (input.id) {
            return this.orderRes.update(input, null, {orderId: input.id});
        } else {
            return this.orderRes.save(input);
        }
    }

    public saveQuotationQueDate(orderId: number, input: OrderQuotationDueDateRequest): Promise<any> {
        return this.orderRes.updateQuotationDueDate(input, null, {orderId});
    }

    public acceptQuotation(orderId: number, input: OrderQuotationAcceptRequest): Promise<any> {
        return this.orderRes.acceptQuotation(input, null, {orderId});
    }

    public approveQuotation(orderId: number): Promise<any> {
        return this.orderRes.approveQuotation({orderId});
    }

    public additionalRequest(orderId: number, input: OrderQuotationOfferCommentRequest): Promise<any> {
        return this.orderRes.addOfferComment(input, null, {orderId});
    }

    public savePublicOrder(input: any): Observable<HttpEvent<any>> {
        return this.orderFileRes.saveOrder(input);
    }

    public saveOrderTenants(orderId: number, input: OrderTenantsInput): Promise<OrderTenant[]> {
        return this.orderRes.saveTenants(input, null, {orderId});
    }

    public saveCost(input: CostInput): Promise<CostsOutput> {
        if (input.id) {
            return this.orderRes.updateCost(input, null, {orderId: this.order.id, costId: input.id});
        } else {
            return this.orderRes.saveCost(input, null, {orderId: this.order.id});
        }
    }

    public deleteCost(costId: number): Promise<void> {
        return this.orderRes.deleteCost({orderId: this.order.id, costId});
    }

    public saveCosts(input: CostsInput): Promise<CostsOutput> {
        return this.orderRes.updateCosts(input, null, {orderId: this.order.id});
    }

    public submitWorkflowAction(orderId: number, input: OrderEventInput): Promise<OrderStatus> {
        return this.orderRes.updateEvent(input, null, {orderId});
    }

    public saveAssignee(orderId: number, input: OrderAssigneeInput): Promise<OrderAssignee> {
        return this.orderRes.updateAssignee(input, null, {orderId});
    }

    public canShowCosts(permissions, order: Order, events: OrderEvent[], costs: Cost[]): boolean {
        if (!permissions['order.cost.view']) return false;
        if (order.orderStatusType === 'ORST_ACCEPTED' && !!costs?.length) return true;
        if (order.orderStatusType === 'ORST_CLOSED' && events.every(e => e.orderEventType !== 'OREV_CANCEL')) return true;

        return ['ORST_REQUESTED', 'ORST_ACCEPTED', 'ORST_CANCELLED'].indexOf(order.orderStatusType) === -1;
    }

    public updateBuilding(orderId: number, input: OrderBuildingInput): Promise<OrderBuilding> {
        return this.orderRes.updateBuilding(input, null, {orderId});
    }

    public createApplianceOrder(applianceId: number, description: string): Promise<Order> {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as IdInput,
            service: {id: this.order.service.id} as IdInput,
            building: {id: this.order.building.id} as IdInput,
            isSpecificDueDate: false,
            orderSourceType: 'ORSO_WORK_ORDER',
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
            applianceIds: [applianceId],
            preventiveMaintenanceOrderId: this.order.id,
            description
        } as OrderInput;

        return this.saveOrder(input);
    }

    public verifyWork(orderId: number, input: OrderVerifyWorkRequest): Promise<any> {
        return this.orderRes.verifyWork(input, null, {orderId});
    }

    public setOrderViewer(state: boolean): Observable<void> {
        const request = new OrderViewerRequest();
        request.viewing = !!state;

        return from(this.orderRes.setOrderViewer(request, null, {orderId: this.order.id}));
    }

    public duplicateOrder(
        description: string,
        specialWork: OrderInput,
        sourceType: string,
        inspectionId: number
    ): Promise<Order> {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as IdInput,
            requesterEmail: this.authService.getCurrentUser()?.email,
            requesterPhone: this.authService.getCurrentUser()?.phone,
            building: {id: this.order.building.id} as IdInput,
            isSpecificDueDate: false,
            orderSourceType: sourceType,
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
            description,
            duplicateOrderId: this.order.id,
            ...specialWork
        } as OrderInput;

        if (!input.specialWorkOfWarranty && !input.specialWorkOfRepair && !!this.order?.service?.id) {
            input.service = new IdInput();
            input.service.id = this.order?.service?.id;
        }

        if (!!this.order.appliances.length) {
            input.applianceIds = this.order.appliances.map(a => a.id);
        }

        if (!!inspectionId) {
            input.inspection = {id: inspectionId} as IdInput;
        }

        return this.saveOrder(input);
    }

    public saveFiles(orderId: number): Promise<void> {
        return new Promise((resolve, reject) => {
            Promise.all(this.files.map(file => this.orderRes.saveFile(file, null, {orderId})))
                .then(__ => resolve())
                .catch(err => reject(err));
        });
    }

    public saveInspectionOrder(row: CancelledInspectionOrder) {
        const input = {
            requesterNotUser: false,
            requesterUser: {id: this.authService.getCurrentUser().id} as IdInput,
            requesterCompany: {id: this.authService.getCurrentUserCompany().companyId} as IdInput,
            requesterEmail: this.authService.getCurrentUser()?.email,
            requesterPhone: this.authService.getCurrentUser()?.phone,
            building: {id: row?.buildingId} as IdInput,
            location: row?.location,
            description: row?.description,
            deadlineHours: row?.deadlineHours,
            isSpecificDueDate: row?.isSpecificDueDate,
            orderSourceType: OrderSourceType.ORSO_WORK_ORDER,
            inChargeCompanyId: row?.inChargeCompanyId,
            inChargeUserId: row?.inChargeUserId,
            isPropertyManagersOrder: row?.isPropertyManagersOrder,
            specialWorkOfWindowsOrder: row?.isSpecialWorkOfWindowsOrder,
            specialWorkOfFloorsOrder: row?.isSpecialWorkOfFloorsOrder,
            specialWorkOfWarranty: row?.isSpecialWorkOfWarranty,
            specialWorkOfRepair: row?.isSpecialWorkOfRepair,
            specialWorkOfMaintenance: row?.isSpecialWorkOfMaintenance,
            costOwnerType: 'COOW_CONTRACT_DIVISIBLE_ALL_TENANTS',
        } as OrderInput;

        if (!!row.dueDateTime) {
            input.dueDateTime = row.dueDateTime.toString();
        }

        return this.saveOrder(input);
    }

    private listenForOrderUpdate(): void {
        this.refreshOrder$.subscribe({
            next: orderId => {
                if (!ObjectUtils.hasValue(orderId)) return;

                Promise.all([
                    this.loadAssignees(),
                    this.loadCosts(),
                    this.loadCostOptions(),
                    this.loadEvents(),
                    this.loadFiles()
                ]).catch(err => {
                    this.toastr.error('Töötaotluse info laadimise viga!');
                    console.error(err);

                    this.router.navigate(['/']);
                });
            }
        });
    }
}
