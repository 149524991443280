export type StringLike = string | null | undefined;

export class StringUtil {

    public static toString(input: any): StringLike {
        if ([null, undefined].includes(input) || StringUtil.isString(input)) return input;

        return input.toString();
    }

    public static isString(input: any): boolean {
        return (typeof input === 'string' || input instanceof String);
    }

    public static isBlank(input: string): boolean {
        if (!input) return true;

        return !input.trim().length;
    }

    public static includesIgnoreCase(input: string, searchTerm: string): boolean {
        if (input === null) return searchTerm === null;
        if (input === undefined) return searchTerm === undefined;
        if ([null, undefined].includes(searchTerm)) return false;

        return input.toLowerCase().includes(searchTerm.toLowerCase());
    }
}
