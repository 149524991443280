import * as moment from 'moment';

export class DateUtils {

    public static readonly DATE_FORMAT = 'YYYY-MM-DD';

    public static readonly TIMESTAMP_FORMAT = 'YYYY-MM-DD\'T\'HH:mm:ss.SSS';

    public static readonly SERIALIZE_TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

    public static serialize(date: Date, format?: string): string {
        if (!format) {
            format = this.DATE_FORMAT;
        }
        return date ? moment(date).format(format) : null;
    }

    public static serializeTimestamp(date: Date, format?: string): string {
        if (!format) {
            format = this.SERIALIZE_TIMESTAMP_FORMAT;
        }
        return this.serialize(date, format);
    }

    public static deserialize(date: string, format?: string): Date | null {
        if (!date) return null;

        if (!format) {
            format = this.DATE_FORMAT;
        }
        const asMoment = moment(date, format);

        return asMoment.isValid() ? asMoment.toDate() : null;
    }

    public static deserializeTimestamp(date: string, format?: string): Date | null {
        if (!format) {
            format = this.TIMESTAMP_FORMAT;
        }
        return this.deserialize(date, format);
    }

    public static isToday(date: Date): boolean {
        return moment(date).isSame(new Date(), 'date');
    }

    public static isTomorrow(date: Date): boolean {
        return moment(date).isSame(moment().add(1, 'days'), 'date');
    }

    public static today(): Date {
        return moment().startOf('day').toDate();
    }

    public static reverseStringDate(date: string): string {
        const parts: string[] = date.split('.');

        return parts[2] + '-' + parts[1] + '-' + parts[0];
    }
}
