import {ActivityType} from '@app/core/constants/activity.type';
import {ActivityStatus} from '@app/core/constants/activity-status';
import {ActivityUserResponse} from '@app/core/models/activity/activity-user-response.model';
import {DateUtils} from '@app/shared/utils';

export class ActivityResponse {

    public id: number;
    public type: ActivityType;
    public startedAt: Date;
    public finishedAt?: Date;
    public status: ActivityStatus;
    public cause?: string;
    public user?: ActivityUserResponse;

    public static from(json?: any): ActivityResponse | undefined {
        if (!json) return undefined;

        const output = new ActivityResponse();
        output.id = json?.id;
        output.type = json?.type;
        output.startedAt = DateUtils.deserializeTimestamp(json?.startedAt) || undefined;
        output.finishedAt = DateUtils.deserializeTimestamp(json?.finishedAt) || undefined;
        output.status = json?.status;
        output.cause = json?.cause;
        output.user = ActivityUserResponse.from(json?.user);

        return output;
    }
}
