import {ObjectUtils} from '@app/shared/utils/object.utils';

export class Coordinates {

    public readonly x;
    public readonly y;

    public constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }

    public distance(other: Coordinates): number | undefined {
        if ([this.x, this.y, other?.x, other?.y].some(v => !ObjectUtils.hasValue(v))) return undefined;

        return Math.sqrt(Math.pow((this.x - other.x), 2) + Math.pow((this.y - other.y), 2));
    }

    public absDistance(other: Coordinates): number | undefined {
        return Math.abs(this.distance(other));
    }
}
